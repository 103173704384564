@import '../../../../scss/values';
@import '../../../../scss/mixins/fonts';
@import '../../../../scss/mixins/images';

:host {
  width: 100%;
}

.filters-presets-menu.shared-component {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
  overflow: hidden;

  .selected-filter-button {
    flex: 0 1 auto;
    width: 100%;
    max-width: 100%;
    height: 26px;
    padding: 0 8px;
    overflow: hidden;
    border-color: var(--wheel-scanner-filter-border-color);

    .mat-button-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 4px;
      width: 100%;
      max-width: 100%;
      overflow: hidden;
    }

    .selected-filter-text {
      display: block;
      flex: 1 1 auto;
      @include font-regular(12px, 16px, -0.12px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .mat-icon.presets-arrow-down-icon {
      display: block;
      flex: 0 0 auto;
      color: var(--main-font-color);
      @include apply-icon-size(16px);
    }

    .mat-icon {
      color: var(--main-font-color);
    }

    &.active {
      border-color: var(--primary-color);

      .mat-icon {
        color: var(--primary-color);
        rotate: 180deg;
      }
    }
  }

  .save-filter-btn {
    flex: 0 0 auto;
    background-color: var(--light-bg-17);

    &:hover {
      background-color: var(--light-hover-color-5);
    }

    @media screen and (max-width: $mobile-min-500px-width-trigger) {
      order: 4;
    }
  }
}

// material-components overrides

.cdk-drag-preview.filters-list-item {
  box-sizing: border-box;
  margin: 0;
  padding: 5px 0;
  height: 31px;
  min-height: 31px;
  @include font-regular(14px, 21px, 0.1px);
  line-height: 31px;
  color: var(--primary-color);
  box-shadow: 0 0 20px 0 rgba(black, 0.04);

  .mat-icon.drag-handler {
    margin-right: 0;
  }

  .drop-list-actions {
    display: none;
  }
}
